import React from "react"
import { Link } from "gatsby"

import SEO from "src/components/seo"
import LogoCloud from "src/components/logo-cloud"

import StartTrialBtn from "src/components/startTrialBtn"

import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Footer from "../../components/footer"

export default function WebsiteWalkthroughs() {
  return (
    <>
      <SEO
        title="#1 Website Walkthrough Software: Transform Your Business"
        description="What's the top software for website walkthroughs in 2021? It's Nickelled! Take a free trial today and build an instant walkthrough."
      />
      <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div class="absolute inset-0">
              <div class="absolute inset-0 bg-oOrange"></div>
            </div>
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span class="block text-white">
                  Reduce Support Tickets With
                </span>
                <span class="block text-oOrange-light">
                  Interactive Website Walkthroughs
                </span>
              </h1>
              <p class="mt-6 max-w-lg mx-auto text-center text-xl text-oOrange-light sm:max-w-3xl">
                No more confusion for your customers. No more frustration for
                your support reps. Nickelled makes software user training a
                breeze.
              </p>
              <p class="mt-6 max-w-lg mx-auto text-center text-xl text-oOrange-light sm:max-w-3xl">
                Our drag-and-drop walkthrough creator lets you create
                interactive step-by-step guides for your users. It's the
                fastest, easiest way to create simple or advanced software
                demos.
              </p>

              <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                  <OutboundLink
                    href="https://app.nickelled.com/account/signup/"
                    class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-oOrange bg-white hover:bg-oOrange hover:text-white hover:border-white sm:px-8"
                  >
                    Start a Free Trial of Nickelled
                  </OutboundLink>
                  <p class="max-w-md mx-auto text-oOrange-light text-sm mt-2">
                    Want proof? Start a trial and you'll be set up in 5 minutes
                    or less.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogoCloud></LogoCloud>
      <div class="relative bg-white pt-16 pb-32 overflow-hidden">
        <div class="max-w-7xl mx-auto">
          <div class="mx-auto md:flex items-center px-6 pt-20 md:pt-36">
            <div class="md:w-1/2 md:pr-24 pb-8">
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-oOrange">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      class="h-6 w-6 text-white"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    Auto Play Website Walkthoughs for New Users
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    Automatically run immersive, interactive walkthroughs when
                    new users sign up or log in for the first time. If you don't
                    want to autoplay, use an unobtrusive widget to show a
                    selection of walkthroughs.
                  </p>
                  <div class="mt-6">
                    <StartTrialBtn
                      label="Start free trial"
                      destination="https://app.nickelled.com/account/signup"
                    ></StartTrialBtn>
                  </div>
                </div>
              </div>
              <div class="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p class="text-base text-gray-500">
                      &ldquo;We found Nickelled whilst looking for a solution to
                      onboard our users without the need for extensive dev work
                      - and we haven't looked back since! Perfect solution,
                      great product, and brilliant support.&rdquo;
                    </p>
                  </div>
                  <footer class="mt-3">
                    <div class="flex items-center space-x-3">
                      <div class="flex-shrink-0">
                        <StaticImage
                          src="../images/index/jessica-golding.jpg"
                          class="h-6 w-6 rounded-full"
                          alt="Jessica Golding, 90Seconds"
                        />
                      </div>
                      <div class="text-base font-medium text-gray-700">
                        Jessica Golding, Director of Customer Experience,
                        90Seconds
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div class="relative flex md:w-1/2">
              <div class="absolute inset-0 bg-oOrange rounded-lg transform rotate-3 origin-bottom-right"></div>
              <div className="rounded-lg shadow z-10">
                <div className="bg-white rounded-lg p-3 md:p-6 shadow-lg">
                  <div className="rounded-md overflow-hidden">
                    <StaticImage
                      src="../../images/productScreenshots/step2.png"
                      class=""
                      alt="a step in a Nickelled flow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-7xl mx-auto">
          <div class="mx-auto md:flex items-center px-6 pt-20 md:pt-36">
            <div class="relative flex md:w-1/2">
              <div class="absolute inset-0 bg-oOrange rounded-lg transform -rotate-3 origin-bottom-left"></div>
              <div className="rounded-lg shadow z-10">
                <div className="bg-white rounded-lg p-3 md:p-6 shadow-lg">
                  <div className="rounded-md overflow-hidden">
                    <StaticImage
                      src="../../images/productScreenshots/hotspot.png"
                      class=""
                      alt="a step in a Nickelled flow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="md:w-1/2 md:pl-24 pb-8">
              {" "}
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-oOrange">
                    <svg
                      class="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                      />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    Walkthroughs for Specific Pages or Features
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    Push a walkthrough to your users when they land on a page or
                    encounter a feature they've never used before. Pass us user
                    attributes, and we'll make sure that only the right users
                    see your walkthroughs.
                  </p>
                  <div class="mt-6">
                    <StartTrialBtn
                      label="Start free trial"
                      destination="https://app.nickelled.com/account/signup"
                    ></StartTrialBtn>
                  </div>
                </div>
              </div>
              <div class="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p class="text-base text-gray-500">
                      &ldquo;We love the way Nickelled lets us show users around
                      our site. Now, when a customer really needs our personal
                      support, we have the time and the bandwidth to help
                      them&rdquo;
                    </p>
                  </div>
                  <footer class="mt-3">
                    <div class="flex items-center space-x-3">
                      <div class="flex-shrink-0">
                        <StaticImage
                          src="../images/index/luke-todd.png"
                          class="h-6 w-6 rounded-full"
                          alt="Luke Todd, Gumtree"
                        />
                      </div>
                      <div class="text-base font-medium text-gray-700">
                        Luke Todd, Head of Customer Ops, Gumtree
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-7xl mx-auto">
          <div class="mx-auto md:flex items-center px-6 pt-20 md:pt-36">
            <div class="md:w-1/2 md:pr-24 pb-8">
              <div>
                <div>
                  <span class="h-12 w-12 rounded-md flex items-center justify-center bg-oOrange">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="h-6 w-6 text-white"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    Highlight Walkthroughs Anywhere
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    Use a range of trigger options to show links to your
                    walkthroughs anywhere, without complex coding.
                  </p>
                  <div class="mt-6">
                    <StartTrialBtn
                      label="Start free trial"
                      destination="https://app.nickelled.com/account/signup"
                    ></StartTrialBtn>
                  </div>
                </div>
              </div>
              <div class="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p class="text-base text-gray-500">
                      &ldquo;When I found Nickelled, all my prayers were
                      answered. Now, instead of explaining how to do easy tasks
                      over and over, on a one-to-one basis, I just refer my
                      employees to an intranet page where I've posted all the
                      guides.&rdquo;
                    </p>
                  </div>
                  <footer class="mt-3">
                    <div class="flex items-center space-x-3">
                      <div class="text-base font-medium text-gray-700">
                        Kasia K, Ecommerce Specialist (via Capterra)
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div class="relative flex md:w-1/2">
              <div class="absolute inset-0 bg-oOrange rounded-lg transform rotate-3 origin-bottom-right"></div>
              <div className="rounded-lg shadow z-10">
                <div className="bg-white rounded-lg p-3 md:p-6 shadow-lg">
                  <div className="rounded-md overflow-hidden">
                    <StaticImage
                      src="../../images/productScreenshots/hotspots.png"
                      class=""
                      alt="a step in a Nickelled flow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-oOrange">
        <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 class="text-3xl font-extrabold text-white tracking-tight">
            Nickelled Walkthroughs Are Different
          </h2>
          <p class="mt-4 max-w-3xl text-lg text-oOrange-light">
            We've been building the best website walkthroughs since 2014.
          </p>
          <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-6 w-6 text-white"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">Cross Platform</h3>
                <p class="mt-2 text-base text-oOrange-light">
                  Works on mobile, desktop and tablet.
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-6 w-6 text-white"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">Zero Development</h3>
                <p class="mt-2 text-base text-oOrange-light">
                  No difficult development work, create your first guide in five
                  minutes or less.
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-6 w-6 text-white"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">Zero Downloads</h3>
                <p class="mt-2 text-base text-oOrange-light">
                  No software downloads or browser extensions.
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">
                  Optional Javascript
                </h3>
                <p class="mt-2 text-base text-oOrange-light">
                  Using our Javascript is optional but encouraged. If you can't
                  add Javascript, no problem.
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">
                  Invite the Whole Team
                </h3>
                <p class="mt-2 text-base text-oOrange-light">
                  With unlimited users, anybody can author or edit a Nickelled
                  website walkthrough.
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">
                  World-Class Support
                </h3>
                <p class="mt-2 text-base text-oOrange-light">
                  We respond to most queries within one hour.
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">
                  Clear Pricing
                </h3>
                <p class="mt-2 text-base text-oOrange-light">
                  We have two plans - an off-the-shelf package suitable for most businesses, and a custom package. Get in touch to find out more!
                </p>
              </div>
            </div>

            <div>
              <div>
                <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h3 class="text-lg font-medium text-white">Easy Billing</h3>
                <p class="mt-2 text-base text-oOrange-light">
                  Pay via credit card or annual invoice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div className="max-w-7xl mx-auto py-16 md:px-8">
          <h2 class="my-4 font-bold">What is a website walkthrough tool?</h2>

          <p>
            Website walk-through tools can significantly help to cut user churn,
            sometimes up to 80% or more.
          </p>

          <p>
            A walk-through is a fantastic way to share your app with new users.
            It gives you a chance to highlight the best features, and make sure
            that nobody misses important aspects of the product's functionality
            or capabilities.
          </p>

          <p>
            Website walk-throughs offer a huge advantage of a traditional
            training methods such as videos. Because the user typically complete
            the process at the same time as viewing the walk-through, retention
            is much higher, making them a hugely powerful training tool.
          </p>

          <p>
            In our experience, walk-throughs are most typically used to showcase
            a full process flow, for example, how to create an opportunity in
            salesforce, or how to add a friend in Facebook. This makes them
            slightly different from product stores, which are commonly used to
            give a general overview of a web app interface.
          </p>

          <p>
            Website walk-throughs need to be clear, concise, engaging and
            targeted - there is no point in showing a website walk-through to
            somebody who doesn&rsquo;t need to see it at that point. For this
            reason, and our reviews below, we have made sure only to include
            software packages that allow for full targeting of users.
          </p>

          <h2 class="my-4 font-bold">
            Six Best Website Walkthrough Tools in 2021:
          </h2>

          <ol class="list-decimal ml-8">
            <li> Nickelled </li>
            <li>
              <Link to="/alternatives/appcues-alternative/" class="text-oOrange">
                Appcues
              </Link>
            </li>
            <li>
              <Link to="/alternatives/apty/" class="text-oOrange">
                Apty
              </Link>
            </li>{" "}
            <li>
              <Link to="/alternatives/whatfix-alternative/" class="text-oOrange">
                Whatfix
              </Link>
            </li>
            <li>
              <Link to="/alternatives/walkme-alternative/" class="text-oOrange">
                WalkMe
              </Link>
            </li>
            <li>
              <Link to="/alternatives/chameleon/" class="text-oOrange">
                Chameleon
              </Link>
            </li>
          </ol>

          <h2 class="my-4 font-bold">
            When should I use a website walk-through?
          </h2>

          <p>
            Although the majority of our customers use website walk-throughs for
            new users, there are many different use cases where a website
            walk-through could be an appropriate solution.
          </p>

          <p>For example you might consider using a walk-through when:</p>

          <ul class="list-disc ml-8">
            <li>A user upgrades</li>

            <li>
              A user completes a certain task and get us access to a new feature
            </li>

            <li>
              A user has been using the software for a certain period of time,
              but hasn't moved beyond the most basic features
            </li>

            <li>A user invites a colleague to use the software</li>

            <li>A user logs in after a long period of time</li>

            <li>A user logs in after significant product change</li>
          </ul>

          <h2 class="my-4 font-bold">
            What makes a great website walkthrough tool?
          </h2>

          <p>At the most basic level, a walkthrough tool should be able to:</p>

          <ul class="list-disc ml-8">
            <li>Allow you to show the interface to users at the right time</li>

            <li>
              Allow you to target users based on their role, or level within the
              company
            </li>

            <li>Allow you to change the interface based on the user's role</li>

            <li>Allow you to show different tasks to different users</li>

            <li>
              Allow you to show a web walkthrough from a mobile device or
              desktop
            </li>

            <li>
              Allow you to show a web walkthrough without the need for
              additional coding
            </li>

            <li>Allow you to easily edit the web walkthrough</li>

            <li>Allow you to quickly and easily create a walkthrough</li>

            <li>
              Allow you to create a web walkthrough without the need for
              additional coding
            </li>
          </ul>

          <h2 class="my-4 font-bold">How do website walkthrough tools work?</h2>

          <p>
            A website walkthrough tool is a simple as a piece of software that
            you install on your website. Once installed, it will allow you to
            quickly and easily create a walkthrough for your application. This
            is done by simply clicking and dragging the various screens that you
            would like to include. You can then add text, or scripts, to explain
            the purpose of each button.
          </p>

          <p>
            Once your walkthrough is created, you can then embed it on your
            website. This is typically done by adding a small piece of code to
            the footer of your site. Once this is done, the walkthrough will
            automatically appear when a user lands on your site.
          </p>

          <p>
            A walkthrough will typically appear as a layer that sits over your
            web page, but some walkthrough tools will allow you to integrate it
            directly into the page.
          </p>
        </div>
      </div>
      <div class="relative bg-gray-900">
        <div class="h-80 absolute bottom-0 xl:inset-0 xl:h-full xl:w-full">
          <div class="h-full w-full xl:grid xl:grid-cols-2">
            <div class="h-full xl:relative xl:col-start-2">
              <StaticImage
                src="../../images/landing/website-walkthrough-splash.jpg"
                className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                alt="Nickelled website walkthrough splash"
              />
              <div
                aria-hidden="true"
                class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
              ></div>
            </div>
          </div>
        </div>
        <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
          <div class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
            <h2 class="text-sm font-semibold tracking-wide uppercase">
              <span class="text-oOrange bg-clip-text text-transparent">
                Why Nickelled?
              </span>
            </h2>
            <p class="mt-3 text-3xl font-extrabold text-white">
              We're specialists in helping you to educate your users, before
              they ask.
            </p>
            <p class="mt-5 text-lg text-gray-300">
              Nickelled's website walkthrough tool is 100% codeless, allowing
              you to create a virtual walkthrough of your website or app in
              seconds.
            </p>
            <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
              <p>
                <span class="block text-2xl font-bold text-white">4K+</span>
                <span class="mt-1 block text-base text-gray-300">
                  <span class="font-medium text-white">Companies</span>{" "}
                  improving onboarding with Nickelled.
                </span>
              </p>

              <p>
                <span class="block text-2xl font-bold text-white">5K+</span>
                <span class="mt-1 block text-base text-gray-300">
                  <span class="font-medium text-white">Users</span> signed up to
                  Nickelled.
                </span>
              </p>

              <p>
                <span class="block text-2xl font-bold text-white">29K+</span>
                <span class="mt-1 block text-base text-gray-300">
                  <span class="font-medium text-white">
                    Website walkthroughs
                  </span>{" "}
                  created using Nickelled.
                </span>
              </p>

              <p>
                <span class="block text-2xl font-bold text-white">150K+</span>
                <span class="mt-1 block text-base text-gray-300">
                  <span class="font-medium text-white">Web pages</span> captured
                  with Nickelled.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span class="block">Ready to get started?</span>
            <span class="block text-oOrange bg-clip-text text-transparent">
              Book a demo or start a trial.
            </span>
          </h2>
          <div class="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-oOrange"
              to="/request-demo/"
            >
              Request Demo
            </Link>
            <OutboundLink
              class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-oOrange bg-oOrange-light "
              href="https://app.nickelled.com/account/signup"
              target="_blank"
            >
              Start Trial
            </OutboundLink>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}
